// Theme Customizations to Blocks //

.block-title, 
{
  @extend .more;
  text-align: center;
  display: flex;
  text-transform: uppercase;
}

.footer .block-title,
.footer .views-field-field-event-dates,
.footer .views-field-field-date
{
  color: #b4be8c;
}

.footer .block-title{
  margin-bottom: 40px;
}

.footer .block-title::before, .footer .block-title::after{
  background: repeating-linear-gradient(
  to right,
  $keycolour,
  $keycolour 10px,
  transparent 10px,
  transparent 20px
  );
}

#block-views-news-block .views-field-title a,
#block-views-news-block .views-field-view-node a,
#block-views-news-block-1 .views-field-title a,
#block-views-events-block .views-field-title a,
#block-views-events-block-1 .views-field-title a,
#block-views-events-block .views-field-view-node a,
#block-views-minutes-agendas-block .views-field-title a,
#block-views-minutes-agendas-block-1 .views-field-title a {
  font-family: Latolight, arial, sans-serif;
  font-size: 24px;
  color: $keydark;
  line-height: 1.2em;
}
#block-views-minutes-agendas-block .views-field-title,
#block-views-minutes-agendas-block-1 .views-field-title{
  margin: 10px 0;
  padding-left: 50px;
  padding-bottom: 10px;
  background:url('../images/pdf.png')no-repeat;
}



#block-views-events-block-2 .view-content{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; 
    .views-row{
      max-width: 260px;
    }
  
}

#block-views-events-block-2 .views-field-title a{
  font-family: Latolight, arial, sans-serif;
  font-size: 18px;
  color: #fff;
}

.emailus-block{
  font-family: Latolight, arial, sans-serif;
  font-size: 16px;
  color: #b4be8c;
  a{
    color: #fff;
  }
}

.contactus{
  text-align: center;
  font-family: Latolight, arial, sans-serif;
  font-size: 24px;
  a{color: $darkgreen;}
}

@media (min-width: $screen-sm-min) {
  .emailus-block{
    font-size: 24px;
   }
}

