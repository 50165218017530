// Uncategorized Theme Customizations //

body{
  font-family: arial, verdana, sans-serif;
  color: #666;
  background-color: #f5f5f5;
}

h2.block-title{
  font-family: Latolight, arial, sans-serif;
  font-size: 36px;
  color: $keydark;
}

#block-system-main .field-name-body p:first-of-type, a.more{
  font-family: Latolight, arial, sans-serif;
  font-size: 24px;
}

a.more{
  color: $keydark;
  display: flex;
  text-align: center;
  jusstify-content: center;
  flex-direction: row;
}

a.more:hover{
  text-decoration: none;
}

.more::before, .more::after {
	background-color: $keydark;
	height: 1px;
	content: '\a0';
  flex-grow: 1;
	display: inline-block;
	position: relative;
	top: .65em;
}

.more::before{
  margin-right: 10px;
}

.more::after{
  margin-left: 10px;
}

.page-header,
.dashline{
  @extend .more;
  font-family: Lato, arial, sans-serif;
  text-align: center;
  font-size: 40px;
  color: $keydark;
  display: flex;
}

.page-header::before, 
.page-header::after,
.dashline::before,
.dashline::after{
  background: repeating-linear-gradient(
  to right,
  $keycolour,
  $keycolour 10px,
  transparent 10px,
  transparent 20px
  );
}

.dashline{
  font-size: 0;
}



#headerwrap{
  background-color: $lightgreen;

}

.capwrap{
  display: none;
}

.main-container img{
  border: 5px solid $keycolour;
}

.footer img{
  border: 5px solid #b4be8c;
}

.flexslider{
  border: none;
  margin-bottom: 0;
}


.field-name-field-image .field-items{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#footwrap{
  background: url('../images/footbg.jpg') #687d19 no-repeat;
  padding-top: 240px;
  color: #fff;
}

.region-postfooter{
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  a{
    color: $darkgreen;
  }
  
  padding: 20px 0;
}

.downarrow,
.titledownarrow h2.block-title{
	background: url('/sites/all/themes/highham/images/downarrow.png') no-repeat center bottom;
	padding-bottom: 70px;
	margin-bottom: 50px;
}

.front .field-item{
  padding-bottom: 10px;
}

.node-type-page{ 
  .field-type-image{
    float: right;
    margin-left: 20px;
  }
}

/* 'councillor' and 'group or business' pages */
.node-type-councillor, 
.node-type-directory-listing{ 
  .field-type-image{
    float: right;
    margin-left: 20px;
  }
  
  p:first-of-type{
    font-size: 14px !important;
    font-family: arial, sans-serif !important;
  }
  
  .councillorname{
    background:url('/sites/all/themes/highham/images/person.png') no-repeat;
    padding-left: 50px;
    font-family: latolight, arial, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: 15px;
  }
  
  .business .councillorname{
    background:url('/sites/all/themes/highham/images/industry.png') no-repeat;    
  }
  
  .field-name-field-coun-email-address,
  .field-name-field-gp-email-address{
    background:url('/sites/all/themes/highham/images/at2.png') no-repeat;
    padding-left: 50px;
    font-family: latolight, arial, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    a{color: $darkgreen;}
    margin-left: 15px;
  }
  
  .field-name-field-website-address{
    background:url('/sites/all/themes/highham/images/globe.png') no-repeat;
    padding-left: 50px;
    font-family: latolight, arial, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    a{color: $darkgreen;}
    margin-left: 15px;
  }
  
    .field-name-field-telephone{
    background:url('/sites/all/themes/highham/images/phone.png') no-repeat;
    padding-left: 50px;
    font-family: latolight, arial, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    margin-left: 15px;
  }
  
}

.node-type-events, .node-type-news{
  @extend .node-type-councillor;
  .councillorname{
    background: none;
    padding-left: 0;
    margin-left: 0;
  }
  
  .field-name-field-date, .field-name-field-event-dates{
    font-size: 18px;
    font-family: latolight, arial, sans-serif;
    margin-bottom: 20px;
  }
}


.bean-call-to-action{
  .field-name-field-image .field-item{
    margin: 0 auto;
  }
  
  .field-type-image{
    float: none;
    margin-left: auto;
  }
  
  .field-name-field-website-address{
    margin-top: 20px;
  }
}

svg{
  stroke: $keycolour;
  fill: $keycolour;
}

@media (min-width: $screen-sm-min) {


}

@media (min-width: $screen-md-min) {


}

@media (min-width: $screen-lg-min) {


}



  @media (min-width: 1160px) {
    .container {
      width: 1160px;
      padding: 0;
    }
  }

  @media (max-width: 1250px){
    .flex-caption{
      margin: 0;
      padding: 50px 0;
      border-top: 1px solid;
      border-bottom: 1px solid;
    }
  }

  .navbar-default {
    background-color: transparent;
    border-color: transparent;
  }
  

//Display breakpoints
/*
@media (min-width: $screen-sm-min) {
  body:after {
    content: "Small";
  }
}
@media (min-width: $screen-md-min) {
  body:after {
    content: "Medium";
  }
}
@media (min-width: $screen-lg-min) {
  body:after {
    content: "Large";
  }
}
*/