// Theme Customizations to Navigations //


#navwrap{
   background-color: $darkgreen;
   font-family: 'latolight', sans-serif;
   a{
      color: #fff;
      font-size: 18px;
      text-transform: capitalize;
      border-left: 1px solid;
   }

   li.first a{
    border-left: none;
   }

   a:hover{
     color: $lightgreen;
     background: transparent;
   }
  li ul li a, .sm-collapsible li a{
    font-size: 18px;
    border-left: none;
  }
}

nav{
  clear: both;
}

.caret{
  display: none;
}

.navbar-nav{
  margin: auto;
}

#navbar .nav > li{
  position: relative;
  margin-right: 20px;
  padding-right: 20px;
  ul.dropdown-menu{
    background-color:#fff !important;
  }
}

#navbar .nav > li > a{
  padding: 10px 0 !important;
}

.navbar-nav > li a{
  padding: 3px 15px;
}

.navbar-toggle{
  clear: both;
  background-color: $darkgreen;
  span.icon-bar{
    background-color: #fff !important;
  }
}

#navbar{
  padding-top: 20px;
  margin-bottom: 0;
 
}

#navbar .navbar-header{
  margin: 0 auto;
}

.navbar-brand{
  float: none;
  padding: 0;
  line-height: normal;
  height: auto;
  margin-left: auto !important;
}

.navbar-nav li .dropdown-menu, .nav .open > a{
  background-color: $darkgreen;
}

  .head_email{
    display: none;
  }

@media (min-width: $screen-md-min) {
  .navbar-brand{
    float: left;
  }
}

@media (min-width: $screen-lg-min) {

  nav{
    display: block;
  }

  .navbar-nav{
    list-style: disc outside none;
    display: flex;
    justify-content: flex-start;
    float: none;
    padding: 15px 0;

    ul{
        width: auto !important;
        max-width: inherit !important;
      }
  }

  .navbar-nav > li > a::after{
  //  content: '\2022';
    margin-left: 20px;
    color: rgba(225, 225, 225, 0.3);
    font-size: 1.3em;
    position: absolute;
    right: -30px;
  }


  .navbar-nav li.last{
    border: none;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  
  #navbar{
     background: url('/sites/all/themes/highham/images/at.png')no-repeat right;
  }

  #navbar .nav li a{
    padding: 0 !important;
  }

  .navbar-nav > li.last > a::after{
    display: none;
  }

  ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block;
    padding: 10px;

  }


  ul.nav li.dropdown ul.dropdown-menu {
    margin-top: 0;
    li{
      border-bottom: 1px solid #999;
      border-right: none;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    li.last{
      border-bottom: none;
    }
  }
  
  .head_email{
    color: #fff;
    font-size: 18px;
    margin-top: 40px;
    font-weight: bold;
    display: block;
  }
}

#navbar .nav li ul a{
  font-size: 1.3em;
}

