// Theme Customizations to Forms/Webforms //

/* Colours 
$keycolour: #00bff3;
$grey: #999999;
$midgrey: #333333;
$darkgrey: #959595;
$standardtext: #666666;
$lightgrey: #f0f0f0;
$darkblue: #0b7c9b;
*/

.form-control
{
  border: 1px solid $keycolour;
  padding: 15px;
  min-height: 50px;
}

 label {
    color: #666;
    font-size: 18px;
    font-weight: normal;
    display: block;
    text-align: center;
    margin: 20px;
  }

  .form-required{
  	color: $keycolour;
  }

.form-managed-file{
	position: relative;
}

#edit-submitted-logo-upload-button{
	position: absolute;
	right: 10px;
	top: 20%;
	background-color: $keycolour;
	border-radius: 15px;
	border: none;
	color: #fff;
}


.webform-submit{
  width: 100%;
  background-color: $keycolour;
  border: none;
  padding: 5px 0;
  font-size: 50px;
  font-family: latolight, arial, sans-serif;
}



.form-actions button{
	width: 100%;
	text-transform: capitalize;
	padding: 20px;
	margin-top: 65px;
}

.req{
	font-size: .25em;
	display: block;
	border-top: 1px solid rgba(255,255,255,.5);
	padding-top: 20px;
	text-transform: lowercase;
}