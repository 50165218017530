// Theme Customizations to Views //
.view-content{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.views-field-field-image, .views-field-nothing{
  margin-top: 20px;
}

.front .views-field-field-image{
  margin-top: 0;
}

.fourgrid .views-row{
  max-width: 240px;
}

.view-minutes-agendas .view-content{
  flex-direction: column;
  
}

.view-gallery{
  .view-content{
    justify-content: space-between;
  }
  
  .view-header{
    font-family: latolight, arial, sans-serif;
    font-size: 24px;
  }
  .views-row{
    padding: 10px 20px;
  }
} 